
@font-face {
  font-family: 'massimo-grafia';
  src: url('../fonts/MassimoGrafia-Medium.woff2') format('woff2');
  font-weight: medium;
  font-style: normal;
}

/*
* Web Fonts from colophon-foundry.org
*
* The fonts included in this stylesheet are subject to the End User License you purchased
* from Colophon Foundry. The fonts are protected under domestic and international trademark and
* copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
* distributing this font software.
*
* (c) 2023 Colophon Foundry
*
* Licenced to Kai Sachse, Clubcommission Netzwerk der Berliner Clubkultur e.V.
*/

@font-face {
  font-family: 'basis-grotesque-regular-pro';
  src: url('../fonts/basis-grotesque-regular-pro.woff2') format('woff2'),
      url('../fonts/basis-grotesque-regular-pro.woff') format('woff'),
      url('../fonts/basis-grotesque-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.ot-aalt-0 {
  font-feature-settings: 'aalt' 0;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-1 {
  font-feature-settings: 'aalt' 1;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-2 {
  font-feature-settings: 'aalt' 2;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-3 {
  font-feature-settings: 'aalt' 3;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-4 {
  font-feature-settings: 'aalt' 4;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-5 {
  font-feature-settings: 'aalt' 5;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-6 {
  font-feature-settings: 'aalt' 6;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-7 {
  font-feature-settings: 'aalt' 7;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-8 {
  font-feature-settings: 'aalt' 8;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-9 {
  font-feature-settings: 'aalt' 9;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-10 {
  font-feature-settings: 'aalt' 10;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-11 {
  font-feature-settings: 'aalt' 11;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-12 {
  font-feature-settings: 'aalt' 12;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-13 {
  font-feature-settings: 'aalt' 13;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-14 {
  font-feature-settings: 'aalt' 14;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-15 {
  font-feature-settings: 'aalt' 15;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-16 {
  font-feature-settings: 'aalt' 16;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-17 {
  font-feature-settings: 'aalt' 17;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-18 {
  font-feature-settings: 'aalt' 18;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-19 {
  font-feature-settings: 'aalt' 19;
  font-variant-ligatures: no-contextual;
}

.ot-aalt-20 {
  font-feature-settings: 'aalt' 20;
  font-variant-ligatures: no-contextual;
}
:root {
    --deactivated: #D8D3CC;
    --plyr-color-main: var(--primary, #238764);
    --plyr-control-radius: 0;
}

.plyr__control--overlaid {
    border-radius: 0;
    opacity: 1;
}


.bg-primary {
    background-color: var(--primary, #238764);
}

.bg-secondary {
    background-color: var(--secondary, #5046FA);
}

.text-primary {
    color: var(--primary, #238764);
}

.text-secondary {
    color: var(--secondary, #5046FA);
}

.border-primary {
    border-color: var(--primary, #238764);
}

.border-secondary {
    border-color: var(--secondary, #5046FA);
}

[x-cloak] { display: none !important; }

.text-sideways {
    writing-mode: vertical-rl;
}
body {
  transition: background-color 0.2s ease;
}

/* Define a transition duration during page visits */
html.is-changing .transition-slide {
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
  opacity: 1;
}

/* Define the styles for the unloaded pages */
html.is-animating .transition-slide {
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
  transform: translateX(-1rem);
  opacity: 0;
}


/* Define a transition duration during page visits */
html.is-changing .transition-fade {
  transition: opacity 0.15s ease-out;
  opacity: 1;
}

/* Define the styles for the unloaded pages */
html.is-animating .transition-fade {
  transition: opacity 0.3s ease-out;
  opacity: 0;
}